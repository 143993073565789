<template>
  <section class="">
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>

    <!-- property filters -->
    <section class="section has-background-eurotext-light">
      <div class="container">
        <form @submit.prevent="getElements" @reset.prevent="resetForm">
          <div class="columns is-multiline">
            <div
              v-for="(filter, index) in filters"
              :key="`${filter.id}_${index}`"
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align"
            >
              <b-field>
                <template #label>
                  <span class="has-text-light">{{
                    $t(`policies.search.${filter.label}`)
                  }}</span>
                </template>

                <!-- Type -->
                <b-select
                  v-if="filter.id === 'type'"
                  v-model="filter.value"
                  expanded
                >
                  <option :value="null"></option>
                  <option
                    v-for="option in folderTypes"
                    :value="option.id"
                    :key="option.key"
                  >
                    {{ $t(`policies.folder.type.${option.id}`) }}
                  </option>
                </b-select>

                <!-- Status -->
                <b-select
                  v-else-if="filter.id === 'status'"
                  v-model="filter.value"
                  expanded
                >
                  <option :value="null"></option>
                  <option
                    v-for="option in statusOptions"
                    :value="option.value"
                    :key="option.key"
                  >
                    {{ option.label }}
                  </option>
                </b-select>

                <b-input v-else v-model="filter.value" expanded></b-input>
              </b-field>
            </div>
          </div>

          <div class="buttons">
            <b-button
              type="is-primary"
              native-type="submit"
              icon-left="magnify"
            >
              {{ $t("buttons.search") }}
            </b-button>
            <b-button type="is-primary" native-type="reset" icon-left="eraser">
              {{ $t("buttons.erase") }}
            </b-button>
          </div>
        </form>
      </div>
    </section>

    <section class="section">
      <document-table
        :data="tableData"
        :tableColumns="tableColumns"
        :isLoadingRows="loadingElems"
        :perPage="perPage"
        :totalRecords="totalElems"
        :checkable="false"
        :detailed="true"
        :clickable="false"
        :defaultOrderedColumnsNames="[
          '_status_color',
          'status',
          'status_description_label',
        ]"
        @page="onTablePage($event)"
      >
        <template #cell="{ cellData, column, row }">
          <!-- <span
              v-if="column.field === 'status'"
              :class="`tag is-medium is-itside-${cellData}`"
              >{{ $t(`einvoice.status.options.${cellData}`) }}</span
            > -->
          <span v-if="column.field === 'status'" class="has-text-weight-bold">{{
            $t(`policies.status.options.${cellData}`)
          }}</span>
          <span v-else-if="column.field === '_status_color'">
            <b-icon
              icon="circle"
              size="is-large"
              :type="`is-itside-${row['status']}`"
            ></b-icon>
          </span>

          <span v-else-if="column.field === 'type'">{{
            $t(`policies.folder.type.${cellData}`)
          }}</span>

          <!-- <span v-else-if="column.field === 'set_as_paid_enabled'">
            <b-icon
              :icon="`${row['set_as_paid_enabled'] ? 'check' : 'close'}`"
              size=""
              :type="`${
                row['set_as_paid_enabled'] ? 'is-success' : 'is-danger'
              }`"
            ></b-icon>
          </span> -->

          <span v-else-if="column.field === 'buttons'">
            <div class="is-flex is-justify-content-flex-end" style="gap: 6px">
              <b-tooltip
                :label="$t('policies.search.tooltip.set_as_payed')"
                type="is-primary"
              >
                <button
                  v-if="
                    row['set_as_paid_enabled'] &&
                    row['status_description'] === 'PAYMENT_NEEDED'
                  "
                  type="button"
                  class="button is-primary"
                  @click.stop="openSetPolicyPaymentDateModal(row)"
                >
                  <b-icon icon="cash-check"></b-icon>
                </button>
              </b-tooltip>

              <b-tooltip
                :label="$t('policies.search.tooltip.invalidate')"
                type="is-warning"
              >
                <button
                  v-if="row['stoppable']"
                  type="button"
                  class="button is-warning"
                  @click.stop="invalidatePolicy(row)"
                >
                  <b-icon icon="close-octagon"></b-icon>
                </button>
              </b-tooltip>

              <b-tooltip
                :label="$t('policies.search.tooltip.delete')"
                type="is-danger"
              >
                <button
                  v-if="row['erasable']"
                  type="button"
                  class="button is-danger"
                  @click.stop="deletePolicy(row)"
                >
                  <b-icon icon="delete"></b-icon>
                </button>
              </b-tooltip>
            </div>
          </span>
          <span v-else>{{ cellData }}</span>
        </template>

        <template #detail="{ row }">
          <b-table
            :data="row.document"
            :striped="false"
            :narrowed="false"
            :hoverable="true"
            :mobile-cards="true"
            :row-class="(row, index) => 'center-cell-vertical is-clickable'"
            @click="openPolicy($event, row)"
          >
            <b-table-column
              v-for="(col, index) of tableDetailColumns"
              :key="col.field + '_' + index"
              :label="col.label"
              :field="col.field"
              v-slot="props"
            >
              <span v-if="col.field === 'doc_type'">{{
                $t(`policies.${props.row[col.field]}.name`)
              }}</span>
              <span v-else-if="col.field === 'status'">{{
                $t(`policies.doc_status.${props.row[col.field]}`)
              }}</span>
              <span v-else>{{ props.row[col.field] }}</span>
            </b-table-column>
          </b-table>
        </template>
      </document-table>
    </section>
  </section>
</template>

<script>
import { commonMixin, templateMixin } from "@/mixins";
import { InsuranceFilters, folderTypes } from "../../helpers/constants";
// import FolderTableAdvanced from "../../components/policies/FolderTableAdvanced.vue";
import DocumentTable from "../../components/DocumentTable/DocumentTable.vue";
import { policiesService } from "../../services";
import { b64ToFile } from "../../helpers";
import PolicyPaymentDateSelectionVue from "../../components/policies/search/PolicyPaymentDateSelection.vue";
export default {
  components: { DocumentTable },
  mixins: [commonMixin, templateMixin],
  data() {
    return {
      // loadingMerchants: false,
      loadingElems: false,
      loadingDoc: false,
      filters: [],
      tableData: [],
      perPage: 20,
      page: 1,
      totalElems: 0,
      folderTypes: folderTypes,
      statusOptions: [
        {
          label: this.$t("policies.status.options.OK"),
          value: "OK",
        },
        {
          label: this.$t("policies.status.options.WAITING"),
          value: "WAITING",
        },
        {
          label: this.$t("policies.status.options.ERROR"),
          value: "ERROR",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.loadingElems || this.loadingMerchants || this.loadingDoc;
    },
    tableColumns() {
      const el = this.tableData[0];
      if (el) {
        const exclude = [
          "document",
          "stoppable",
          "erasable",
          "status_description",
          "set_as_paid_enabled",
        ];
        const columns = Object.keys(el)
          .filter((prop) => !exclude.includes(prop))
          .map((prop) => {
            const obj = {
              label: this.$t(`policies.search.${prop}`),
              field: prop,
              headerClass: "_",
            };
            if (obj.field === "status") {
              obj.cellClass = "v-align-middle";
            }
            if (obj.field === "stoppable") {
              obj.label = "";
            }
            return obj;
          });
        columns.push({
          label: this.$t("einvoice.status.color.label"),
          field: "_status_color",
          centered: true,
          headerClass: "width-fit",
          cellClass: "v-align-middle",
        });
        columns.push({
          label: "",
          field: "buttons",
          headerClass: "width-fit",
          cellClass: "v-align-middle",
        });
        return columns;
      } else {
        return [];
      }
    },
    tableDetailColumns() {
      const el = this.tableData[0];
      if (el && el["document"] && el["document"][0]) {
        const doc = el["document"][0];
        const columns = Object.keys(doc).map((prop) => {
          const obj = {
            label: this.$t(`policies.search.${prop}`),
            field: prop,
          };
          if (obj.field === "status") {
            obj.cellClass = "v-align-middle";
          }
          return obj;
        });
        return columns;
      } else {
        return [];
      }
    },
  },
  methods: {
    async getElements() {
      try {
        this.loadingElems = true;
        const _filters = {};
        this.filters.forEach((f) => {
          if (f.value) {
            _filters[f.id] = f.value;
          }
        });
        const data = await policiesService.searchPolicies(
          this.companyName,
          this.merchantUniqueName,
          this.page,
          this.perPage,
          _filters
        );
        const groups = data["groups"];
        groups.forEach((g) => {
          switch (g.status) {
            case "ERROR": {
              g["status_description_label"] = this.$t(
                `policies.status.ERROR.${g["status_description"]}`
              );
              break;
            }
            case "WAITING": {
              let arr = g["status_description"].split(":");
              if (arr[0].includes("SIGN_")) {
                // If there is "SIGN_", there is also the username folowwing the ":"
                let role = arr[0].replace("SIGN_", "").toLowerCase();
                let tRole = this.$t(`policies.roles.${role}`);
                g["status_description_label"] = this.$t(
                  `policies.status.WAITING.description`,
                  {
                    role: tRole,
                    user: arr[1],
                  }
                );
              } else {
                g["status_description_label"] = this.$t(
                  `policies.status.WAITING.${arr[0]}`
                );
              }
              break;
            }
            default: {
              g["status_description_label"] = "";
            }
          }
        });
        this.tableData = groups;
        this.totalElems = data["items_found"];
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingElems = false;
      }
    },
    async deletePolicy(policy) {
      try {
        this.loadingDoc = true;
        await policiesService.deletePolicy(this.companyName, policy.id);
        this.getElements();
      } catch (e) {
        console.log(e);
        if (e.response && e.response.data && e.response.data.code === -10111) {
          this.$buefy.toast.open({
            message: this.$t("policies.search.error.not_exists"),
            type: "is-danger",
            duration: 4000,
          });
        }
      } finally {
        this.loadingDoc = false;
      }
    },
    async invalidatePolicy(policy) {
      try {
        this.loadingDoc = true;
        await policiesService.invalidatePolicy(this.companyName, policy.id);
        this.getElements();
      } catch (e) {
        console.log(e);
        if (e.response && e.response.data && e.response.data.code === -10111) {
          this.$buefy.toast.open({
            message: this.$t("policies.search.error.not_exists"),
            type: "is-danger",
            duration: 4000,
          });
        }
      } finally {
        this.loadingDoc = false;
      }
    },
    onTablePage(event) {
      this.page = event.page;
      this.perPage = event.rows;
      this.getElements();
    },
    async openPolicy(doc, policy) {
      console.log(doc);
      try {
        const docId = doc.id;
        this.loadingDoc = true;
        const res = await policiesService.displayDocument(
          this.companyName,
          docId
        );
        let pdf = b64ToFile(
          res,
          `${policy["external_id"]}_${docId}.pdf`,
          "application/pdf"
        );
        const url = URL.createObjectURL(pdf);
        // let pdf = "data:application/pdf;base64,".concat(encodeURI(res));
        const tab = {
          id: `pol_${docId}`,
          component: "IFrame",
          label: `${policy["external_id"]}_${docId}`,
          icon: "file-pdf",
          props: {
            url: url,
            mimeType: "application/pdf",
          },
        };
        this.$store.dispatch("tabs/openTab", tab);
      } catch (e) {
        console.log(e);
        if (e.response && e.response.data && e.response.data.code === -10111) {
          this.$buefy.toast.open({
            message: this.$t("policies.search.error.not_exists"),
            type: "is-danger",
            duration: 4000,
          });
        }
      } finally {
        this.loadingDoc = false;
      }
    },
    resetForm() {
      this.filters.forEach((f) => (f.value = null));
    },
    openSetPolicyPaymentDateModal(policy) {
      this.$buefy.modal.open({
        parent: this,
        component: PolicyPaymentDateSelectionVue,
        hasModalCard: true,
        customClass: "",
        trapFocus: true,
        props: {
          policyId: policy.id,
        },
        events: {
          select: (obj) => this.setPolicyAsPaid(obj.policyId, obj.date),
        },
      });
    },
    async setPolicyAsPaid(policyId, date) {
      try {
        this.loadingDoc = true;
        await policiesService.setPolicyAsPaid(this.companyName, policyId, date);
        this.getElements();
      } catch (e) {
        console.log("setPolicyAsPaid error", e);
        if (e.response && e.response.data && e.response.data.code === -10111) {
          this.$buefy.toast.open({
            message: this.$t("policies.search.error.not_exists"),
            type: "is-danger",
            duration: 4000,
          });
        }
      } finally {
        this.loadingDoc = false;
      }
    },
  },
  mounted() {
    // this.loadingMerchants = true;
    // this.fetchMerchants();
    // .then(() => {
    //   this.loadingMerchants = false;
    // });
  },
  created() {
    this.filters = InsuranceFilters.map((o) => {
      return {
        ...o,
        value: null,
      };
    });
  },
};
</script>

<style></style>
