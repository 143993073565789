<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t("policies.search.date_selection_header") }}
      </p>
    </header>
    <section class="modal-card-body">
      <b-field>
        <b-input icon="calendar" :value="paymentDateString" readonly></b-input>
      </b-field>
      <b-field>
        <b-datepicker
          inline
          v-model="paymentDate"
          :locale="$i18n.locale"
          :first-day-of-week="1"
          class="is-flex is-justify-content-center"
        ></b-datepicker>
      </b-field>
    </section>
    <section class="modal-card-foot">
      <b-button :label="$t('buttons.cancel')" @click="$emit('close')" />
      <b-button
        :label="$t('buttons.confirm')"
        type="is-primary"
        @click="confirmDate"
      />
    </section>
  </div>
</template>

<script>
import { formatDate } from "../../../helpers/date";
export default {
  props: {
    policyId: {
      required: true,
    },
  },
  data() {
    return {
      paymentDate: new Date(),
    };
  },
  computed: {
    paymentDateString() {
      return formatDate(
        this.paymentDate,
        this.$store.getters.getDatePattern,
        this.$i18n.locale
      );
    },
  },
  methods: {
    confirmDate() {
      console.log("confirming");
      this.$emit("select", {
        policyId: this.policyId,
        date: this.paymentDate,
      });
      this.$emit("close");
    },
  },
};
</script>

<style></style>
